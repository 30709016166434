// @ts-strict-ignore
import { useEffect, useState } from 'react'
import { useMediaQuery } from 'react-responsive'

import { useRouter } from 'next/router'

import { mediaQueryIsTablet } from '~/theme/utils/grid'
import { getCookie } from '~/utils/cookie'
import { Separator, UniversalContainer } from '~/utils/grid'
import useUser from '~/utils/hooks/useUser'

import { SearchHeroModalContent } from '~/components/Home/HomePage'
import ReservationModal from '~/components/Reservation/ReservationModal'
import LoginModal from '~/components/User/LoginModal'
import RegisterModal from '~/components/User/RegisterModal'
import UserMenu from '~/components/User/UserMenu'
import { COOKIE, HOME_HERO_SHORTCUTS, INITIAL_NUMBER_OF_ATTENDEES, paths } from '~/constants'
import { useFirstUpcomingReservationQuery } from '~/generated/graphql'
import { gt } from '~/locale'
import Button from '~/shared/atoms/Button'
import Icon from '~/shared/atoms/Icon'
import Link from '~/shared/atoms/Link'
import ShowOnTrigger from '~/shared/atoms/ShowOnTrigger'
import { VisibleIfFeatureFlag } from '~/shared/atoms/VisibleIfFeatureFlag'

import LocationMenu from '../LocationMenu'
import { useModal } from '../Modal'

import {
  Container,
  DesktopNavLinksContainer,
  HeaderLink,
  HeaderLinkWithAnimation,
  Inner,
  Logo,
  Section,
  TabletNavLinksContainer,
  TopBar,
  TopBarContainer,
  Wrapper,
} from './HeaderShards'
import {
  areAnyInPath,
  isLocationSelectorVisibleOnRoute,
  menuModalProps,
  modalProps,
  openConfirmLegalAgeModal,
  searchModalProps,
  TABS,
} from './HeaderUtils'
import MobileHamburgerNav from './MobileHamburgerNav/MobileHamburgerNavView'
import TopBarMenu from './TopBarMenu/TopBarMenuView'

const Header: React.FC = () => {
  const { tp } = gt
  const { user, loading } = useUser()
  const loggedIn = !loading && !!user
  const { data: firstUpcomingReservationData } = useFirstUpcomingReservationQuery({ skip: !loggedIn })
  const isTablet = useMediaQuery({ query: mediaQueryIsTablet })

  const upcomingReservation = firstUpcomingReservationData?.myReservations.edges?.[0]?.node
  const totalCountUpcomingReservations = firstUpcomingReservationData?.myReservations.totalCount
  const [showLocationModal, setShowLocationModal] = useState(false)
  const [showReservationModal, setShowReservationModal] = useState(false)
  const modal = useModal()
  const router = useRouter()

  useEffect(() => {
    const cookieConsent = getCookie(null, COOKIE.ALERT_BOX_CLOSED)
    const isAdult = getCookie(null, COOKIE.ADULT)
    const location = getCookie(null, COOKIE.LOCATION)

    if (cookieConsent && !isAdult) {
      openConfirmLegalAgeModal(modal.open, () => {
        !location && setShowLocationModal(true)
        modal.close()
      })
    }

    if (cookieConsent && isAdult && !location) {
      setShowLocationModal(true)
    }

    window &&
      window?.OneTrust &&
      window.OneTrust?.OnConsentChanged(() => {
        const updatedCookieConsent = getCookie(null, COOKIE.ALERT_BOX_CLOSED)
        if (modal && updatedCookieConsent) {
          !isAdult &&
            openConfirmLegalAgeModal(modal.open, () => {
              !location && setShowLocationModal(true)
              modal.close()
            })
          isAdult && !location && setShowLocationModal(true)
        }
      })
  }, [])

  const shouldShowLocationMenu = !isTablet && isLocationSelectorVisibleOnRoute(router.route)

  return (
    <Wrapper>
      <TopBarContainer>
        <TopBar>
          <TopBarMenu onClose={modal.close} />
        </TopBar>
      </TopBarContainer>
      <UniversalContainer>
        <Container>
          <Section>
            <Link href={paths.home}>
              <a>
                <Logo src='/assets/logo/logo-club.svg' alt='RestaurantClub Logo' />
              </a>
            </Link>
            {shouldShowLocationMenu && <LocationMenu showModal={showLocationModal} />}
          </Section>
          <TabletNavLinksContainer gap='1.5rem'>
            {user && (
              <ShowOnTrigger
                placement='down-left'
                type='click'
                trigger={
                  <HeaderLink as='a'>
                    <Icon icon='user-circle' />
                  </HeaderLink>
                }
                contentAsTrigger={true}
                content={<UserMenu user={user} />}
              />
            )}
            <HeaderLink as='a' onClick={() => modal.open(menuModalProps, <MobileHamburgerNav user={user} />)}>
              <Icon icon='hamburger' />
            </HeaderLink>
          </TabletNavLinksContainer>
          {!isTablet && (
            <DesktopNavLinksContainer loggedIn={loggedIn}>
              {TABS.map(({ href, label, matchingUrlFragments, excludedUrlFragments, enabledForFeatureFlag }) => (
                <VisibleIfFeatureFlag name={enabledForFeatureFlag} hasValue={true} key={href}>
                  <Link href={href}>
                    <HeaderLinkWithAnimation
                      as='a'
                      isActive={
                        areAnyInPath([...matchingUrlFragments, href], router.asPath) && !areAnyInPath(excludedUrlFragments, router.asPath)
                      }>
                      {gt.tp('Header', label)}
                    </HeaderLinkWithAnimation>
                  </Link>
                </VisibleIfFeatureFlag>
              ))}
            </DesktopNavLinksContainer>
          )}
          {!isTablet && !loggedIn && (
            <Inner gap='1rem' width='25rem'>
              <HeaderLink onClick={() => modal.open(modalProps, <LoginModal handleClose={modal.close} />)}>
                {tp('Header', 'Sign in')}
              </HeaderLink>
              <Button
                height='small'
                color='orange'
                label={tp('Header', 'Sign up')}
                onClick={() => modal.open(modalProps, <RegisterModal />)}
              />
              <HeaderLink
                as='a'
                onClick={() =>
                  modal.open(
                    searchModalProps,
                    <SearchHeroModalContent
                      shortcuts={HOME_HERO_SHORTCUTS}
                      search={{
                        defaultPeopleCount: INITIAL_NUMBER_OF_ATTENDEES,
                      }}
                      onClose={modal.close}
                    />
                  )
                }>
                <Icon icon='search' />
              </HeaderLink>
            </Inner>
          )}
          {!isTablet && loggedIn && (
            <Inner gap='1.5rem'>
              <ShowOnTrigger
                placement='down-left'
                type='programmatic'
                onClickOutside={() => setShowReservationModal(false)}
                show={showReservationModal}
                trigger={
                  totalCountUpcomingReservations && (
                    <HeaderLink onClick={() => setShowReservationModal(!showReservationModal)}>
                      <Icon
                        icon='calendar'
                        badge={{
                          number: totalCountUpcomingReservations,
                        }}
                      />
                    </HeaderLink>
                  )
                }
                content={
                  upcomingReservation && (
                    <ReservationModal closeModal={() => setShowReservationModal(false)} reservation={upcomingReservation} />
                  )
                }
              />
              <ShowOnTrigger
                placement='down-left'
                type='click'
                trigger={
                  <HeaderLink as='a'>
                    <Icon icon='user-circle' />
                  </HeaderLink>
                }
                content={<UserMenu user={user} />}
                contentAsTrigger={true}
              />
              <HeaderLink
                as='a'
                onClick={() =>
                  modal.open(
                    searchModalProps,
                    <SearchHeroModalContent
                      shortcuts={HOME_HERO_SHORTCUTS}
                      search={{
                        defaultPeopleCount: INITIAL_NUMBER_OF_ATTENDEES,
                      }}
                      onClose={modal.close}
                    />
                  )
                }>
                <Icon icon='search' />
              </HeaderLink>
            </Inner>
          )}
        </Container>
        <Separator />
      </UniversalContainer>
    </Wrapper>
  )
}

export default Header
