// @ts-strict-ignore
import { addFringeSlots } from '~/components/Reservation/SlotSelectionPanel/SlotSelectionPanelUtils'
import { ReservableEnum, RestaurantsListQueryVariables } from '~/generated/graphql'

import { FilterSettings } from '../../ListingPageContent'
import { AllQueryVariables } from '../../ListingPageContent/ListingPageContentTypes'

export const PAGE_SIZE = 6

export const defaultFilterSettings: FilterSettings = {
  tags: [],
  sort: undefined,
  chefsMenu: false,
  region: undefined,
}

export const getRestaurantListQueryVariables = ({
  selectedRegion,
  filters,
  search,
  tagsAndNames,
  endCursor,
  first,
}: AllQueryVariables): RestaurantsListQueryVariables => {
  const { tagIds, restaurantIds } = getTagAndRestaurantIds(tagsAndNames)
  const allTagIds = [...filters.tags.map(({ id }) => id), ...tagIds]
  const neighboringSlots = addFringeSlots([search.slot], 30, 5)

  return {
    region_id: selectedRegion?.id,
    sort: filters.sort,
    reservation_filters: {
      startsOn: search.date.format('YYYY-MM-DD'),
      endsOn: search.date.format('YYYY-MM-DD'),
      hours: [...neighboringSlots],
      peopleCount: search.peopleCount,
      reservableType: ReservableEnum.Daily,
    },
    after: endCursor,
    first: first,
    ...(allTagIds.length && { tag_ids: allTagIds }),
    ...(restaurantIds.length && { ids: restaurantIds }),
    ...('chefsMenu' in filters && filters.chefsMenu && { with_reservable_extras: true }),
  }
}

export const getTagAndRestaurantIds = tagsAndNames => {
  const tagIds = []
  const restaurantIds = []
  tagsAndNames &&
    tagsAndNames.forEach(({ __typename, id }) => {
      __typename === 'Tag' && tagIds.push(id)
      __typename === 'Restaurant' && restaurantIds.push(id)
    })
  return {
    tagIds,
    restaurantIds,
  }
}
