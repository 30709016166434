// @ts-strict-ignore
import { useEffect, useRef, useState } from 'react'

import { CommonNinjaWidget } from 'commonninja-react'

import { hasWindow } from '~/utils/helpers'
import { waitForElement } from '~/utils/waitForElement'

interface Props {
  widgetId: string
}

export const CouponBar = ({ widgetId }: Props) => {
  const containerId = `coupon-bar-widget-${widgetId}`
  const [isLoaded, setIsLoaded] = useState(false)
  const [barHeight, setBarHeight] = useState(0)
  const containerRef = useRef<HTMLDivElement>()
  const shadowRootRef = useRef<ShadowRoot>()

  const mutationObserverRef = useRef(
    hasWindow()
      ? new MutationObserver(() => {
          setBarHeight(shadowRootRef.current.querySelector('.notification-bar')?.getBoundingClientRect().height)
        })
      : null
  )

  useEffect(() => {
    if (isLoaded) {
      waitForElement({ getEl: () => document.getElementById(containerId)?.firstElementChild.shadowRoot }).then(el => {
        shadowRootRef.current = el
        mutationObserverRef.current.observe(el, { childList: true, subtree: true, attributes: true, attributeFilter: ['class', 'style'] })
      })
    }
  }, [containerId, isLoaded])

  return (
    <div ref={containerRef} style={{ height: barHeight }} id={containerId}>
      <CommonNinjaWidget onLoad={() => setIsLoaded(true)} widgetId={widgetId} />
    </div>
  )
}
