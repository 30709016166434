// @ts-strict-ignore
import { CloseIcon, CloseIconContainer, ModalOverlay, ModalWrapper, StyledModal } from './ModalShards'
import { ModalProps } from './ModalTypes'

const ModalCloseIcon = ({ closeIconPosition, iconSize, onClick, showOutsideIconOnMobile }) => {
  return (
    <CloseIconContainer
      onClick={onClick}
      iconSize={iconSize}
      showOutsideIconOnMobile={showOutsideIconOnMobile}
      isOutside={closeIconPosition === 'outside'}>
      <CloseIcon showOutsideIconOnMobile={showOutsideIconOnMobile} isOutside={closeIconPosition === 'outside'} icon={'x'} />
    </CloseIconContainer>
  )
}
const ModalView = ({
  hasVisibleOverlay,
  hasClickableOverlay,
  closeIconPosition,
  handleClose,
  handleClick,
  children,
  position = 'fixed',
  size = 'big',
  iconSize,
  showOutsideIconOnMobile,
  centerVerticallyOnMobile,
  ...props
}: ModalProps) => {
  const onClick = e => {
    hasClickableOverlay && e.stopPropagation()
    handleClick && handleClick()
  }

  const onClose = () => {
    document.body.style.overflow = null
    handleClose()
  }
  const modal = (
    <StyledModal position={position} size={size} iconSize={iconSize || size} onClick={onClick} {...props}>
      {children}
      {closeIconPosition && (
        <ModalCloseIcon
          iconSize={iconSize}
          closeIconPosition={closeIconPosition}
          onClick={onClose}
          showOutsideIconOnMobile={showOutsideIconOnMobile}
        />
      )}
    </StyledModal>
  )

  if (position === 'fixed') {
    document.body.style.overflow = 'hidden'

    return (
      <ModalOverlay
        id='modal_overlay_id'
        isVisible={hasVisibleOverlay}
        centerVerticallyOnMobile={centerVerticallyOnMobile}
        onClick={hasClickableOverlay ? onClose : () => null}>
        <ModalWrapper>{modal}</ModalWrapper>
      </ModalOverlay>
    )
  }

  return <>{modal}</>
}

export default ModalView
