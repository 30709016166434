import { useMediaQuery } from 'react-responsive'

import { mediaQueryIsTablet } from '~/theme/utils/grid'
import { Heading6 } from '~/theme/utils/typography'
import { ClubConfig } from '~/utils/clubConfig'

import { paths } from '~/constants'
import { gt } from '~/locale'
import Link from '~/shared/atoms/Link'
import { VisibleIfFeatureFlag } from '~/shared/atoms/VisibleIfFeatureFlag'

import LanguageSwitcher from '../../LanguageSwitcher'

import { Container, Separator, TopBarLink } from './TopBarMenuShards'

interface Props {
  onClose(): void
}

type TopBarLinkItemProps = {
  href: string
  label: string
  isTablet?: boolean
  onClick: () => void
  enabledForFeatureFlag?: keyof ClubConfig
}

const TopBarLinkItem = ({ href, label, isTablet, onClick, enabledForFeatureFlag }: TopBarLinkItemProps) => {
  const Component = isTablet ? Heading6 : TopBarLink
  return (
    <VisibleIfFeatureFlag name={enabledForFeatureFlag} hasValue={true}>
      <Link href={href}>
        <Component as={'a'} onClick={onClick}>
          {label}
        </Component>
      </Link>
    </VisibleIfFeatureFlag>
  )
}

const TopBarMenu: React.FC<Props> = ({ onClose }) => {
  const isTablet = useMediaQuery({ query: mediaQueryIsTablet })
  const { tp } = gt

  return (
    <Container isTablet={isTablet}>
      {isTablet && <TopBarLinkItem href={paths.blog} label={tp('Header', 'Blog')} isTablet={isTablet} onClick={onClose} />}
      <TopBarLinkItem
        href={paths.about}
        label={tp('Header', 'About us')}
        isTablet={isTablet}
        onClick={onClose}
        enabledForFeatureFlag='aboutPageEnabled'
      />
      <TopBarLinkItem
        href={paths.career}
        label={tp('Header', 'Career')}
        isTablet={isTablet}
        onClick={onClose}
        enabledForFeatureFlag='careerPageEnabled'
      />
      <TopBarLinkItem href={paths.giftEventsListing} label={tp('HomePage', 'As a gift')} isTablet={isTablet} onClick={onClose} />
      <TopBarLinkItem
        href={paths.sideEventsListing}
        label={tp('HomePage', 'Premium Experiences')}
        isTablet={isTablet}
        onClick={onClose}
        enabledForFeatureFlag='sideEventListingPageEnabled'
      />
      {/* <TopBarLinkItem href={paths.partners} label={tp('Header', 'Partners')}
      isTablet={isTablet} onClick={onClose} /> */}
      {!isTablet && <div className='separator' style={{ flexGrow: 1 }} />}
      {/* <TopBarLinkItem href={paths.forRestaurateurs} label={tp('Header', 'For Restaurateurs')} isTablet={isTablet} onClick={onClose} /> */}
      <TopBarLinkItem href={paths.faq} label={tp('Header', 'Help')} isTablet={isTablet} onClick={onClose} />
      {isTablet && <Separator />}
      <LanguageSwitcher variant={isTablet ? 'big' : 'small'} onClose={onClose} />
    </Container>
  )
}

export default TopBarMenu
