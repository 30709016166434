// @ts-strict-ignore
import { ClubConfig } from '~/utils/clubConfig'

import ConfirmLegalAgeModal, { confirmLegalAgeModalProps } from '~/components/User/ConfirmLegalAgeModal'
import { pathFragments, paths } from '~/constants'
import { gt } from '~/locale'

import { ModalProps } from '../Modal/ModalTypes'

export const modalProps: ModalProps = {
  hasVisibleOverlay: true,
  hasClickableOverlay: true,
  closeIconPosition: 'outside',
  fillScreenOnMobile: true,
  handleClick: () => null,
  position: 'fixed',
  size: 'big',
  iconSize: 'big',
}

export const searchModalProps: ModalProps = {
  hasVisibleOverlay: true,
  hasClickableOverlay: true,
  closeIconPosition: 'inside',
  fillScreenOnMobile: true,
  handleClick: () => null,
  position: 'fixed',
  size: 'small',
  iconSize: 'big',
  closeOnEscapeKeyClick: true,
}

export const menuModalProps: ModalProps = {
  hasVisibleOverlay: true,
  hasClickableOverlay: false,
  closeIconPosition: 'outside',
  fillScreenOnMobile: true,
  handleClick: () => null,
  position: 'fixed',
  size: 'small',
  iconSize: 'big',
  closeOnEscapeKeyClick: true,
}

type Tab = {
  label: string
  href: string
  matchingUrlFragments: string[]
  excludedUrlFragments?: string[]
  enabledForFeatureFlag?: keyof ClubConfig
}

export const TABS: Tab[] = [
  {
    label: gt.tp('Header', 'Restaurants'),
    href: paths.restaurants,
    matchingUrlFragments: [pathFragments.restaurants],
    excludedUrlFragments: [pathFragments.festivalRestaurants],
    enabledForFeatureFlag: 'd2dEnabled',
  },
  {
    label: gt.tp('Header', '#ChefsMenu'),
    href: paths.chefsMenuOffers,
    matchingUrlFragments: [pathFragments.chefsMenuOffers],
    enabledForFeatureFlag: 'chmEnabled',
  },
  {
    label: gt.tp('Header', 'Festivals'),
    href: paths.festivals,
    matchingUrlFragments: [pathFragments.festivalRestaurants],
  },
  {
    label: gt.tp('Header', 'Blog'),
    href: paths.blog,
    matchingUrlFragments: [pathFragments.blogPost],
    enabledForFeatureFlag: 'blogEnabled',
  },
]

export const areAnyInPath = (fragments: Array<string> = [], path: string) => {
  const basePath = path.split('?')[0]

  return fragments?.some(fragment => {
    const regex = new RegExp(`(^|/)${fragment}(/|$)`)
    return regex.test(basePath)
  })
}

export const openConfirmLegalAgeModal = (open, onClose) => {
  open(confirmLegalAgeModalProps, <ConfirmLegalAgeModal handleClose={onClose} />)
}

const LOCATION_SELECTOR_VISIBLE_PATHS = [paths.home]

export const isLocationSelectorVisibleOnRoute = (route: string) => LOCATION_SELECTOR_VISIBLE_PATHS.includes(route)
