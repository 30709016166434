// @ts-strict-ignore
import { useEffect } from 'react'

import { Formik } from 'formik'
import Image from 'next/image'
import { useRouter } from 'next/router'

import { ApolloError } from '@apollo/client'

import { Heading2, Heading5 } from '~/theme/utils/typography'
import { formatErrorMessages } from '~/utils/formatErrorMessages'

import { AppleLoginButton } from '~/components/User/AppleLogin/AppleLoginButton'
import RegisterModal from '~/components/User/RegisterModal'
import {
  AppleSignInMutation,
  FacebookSignInMutation,
  GoogleSignInMutation,
  SignInMutation,
  UserDataDocument,
  useSignInMutation,
  useUserDataQuery,
} from '~/generated/graphql'
import { gt } from '~/locale'
import Button from '~/shared/atoms/Button'
import Input from '~/shared/atoms/Input'
import Link from '~/shared/atoms/Link'
import { useToast } from '~/shared/atoms/Toast'
import { bigModalPropsTemplate, smallModalPropsTemplate, useModal } from '~/shared/molecules/Modal'

import FacebookLoginButton from '../FacebookLoginButton'
import ForgotPasswordModal from '../ForgotPasswordModal'
import GoogleLoginButton from '../GoogleLoginButton'

import {
  OtherLogin,
  StyledForm,
  StyledFormLinkContainer,
  StyledFormWrapper,
  StyledImageWrapper,
  StyledLink,
  StyledLoginModal,
  StyledToastContainer,
} from './LoginModalShards'
import { bigModalProps, LOGIN_FORM_FIELDS, loginSchema } from './LoginModalUtils'

interface LoginModalViewProps {
  handleClose: () => void
  onLogin?: (data: SignInMutation | GoogleSignInMutation | FacebookSignInMutation | AppleSignInMutation) => void
}

const LoginModalView = ({ handleClose, onLogin }: LoginModalViewProps) => {
  const { refetch } = useUserDataQuery()
  const [signIn] = useSignInMutation({
    refetchQueries: [{ query: UserDataDocument }, 'UserData'],
    onCompleted: data => {
      onLogin && onLogin(data)
      // not a perfect solution for refetching but its too complicated
      // in MVP to create refetching for specific queries in every page
      // router.reload()
    },
  })
  const modal = useModal()
  const toast = useToast()

  const handleErrors = (error: ApolloError) => {
    const errors = formatErrorMessages(error)
    errors?.map(error =>
      toast.add({
        message: error,
        type: 'error',
        containerSelector: '#LoginToastContainer',
        size: 'small',
      })
    )
  }

  const handleSocialLogin = (data: GoogleSignInMutation | FacebookSignInMutation | AppleSignInMutation | null | undefined) => {
    if (!data) return

    onLogin && onLogin(data)
    refetch()
    handleClose()
  }

  return (
    <StyledLoginModal>
      <StyledFormWrapper>
        <Formik
          initialValues={{
            email: '',
            password: '',
          }}
          validationSchema={loginSchema}
          onSubmit={async (values, { setSubmitting }) => {
            await signIn({
              variables: {
                email: values.email,
                password: values.password,
              },
              onCompleted: handleClose,
              onError: error => handleErrors(error),
            })
            setSubmitting(false)
          }}>
          {({ errors, touched, values, handleSubmit, isSubmitting, isValid, handleChange, handleBlur }) => (
            <StyledForm>
              <Heading2 fontWeight='medium'>{gt.tp('LoginForm', 'Log in')}</Heading2>
              <Heading5 fontWeight='medium'>
                {gt.tp('LoginForm', 'Not registered yet?')}{' '}
                <StyledLink onClick={() => modal.open(bigModalPropsTemplate, <RegisterModal />)}>
                  {gt.tp('LoginForm', 'Create an account')}
                </StyledLink>
              </Heading5>
              <StyledToastContainer id={'LoginToastContainer'} />
              {LOGIN_FORM_FIELDS.map(field => (
                <Input
                  key={field.title}
                  title={gt.tp('LoginForm', field.title)}
                  name={field.name}
                  type={field.type}
                  description=''
                  actionsRight={[]}
                  error={touched[field.name] && !!errors[field.name]}
                  errorMessage={touched[field.name] && gt.tp('Error', errors[field.name])}
                  height={'big'}
                  width={'full'}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values[field.name]}
                />
              ))}
              <StyledFormLinkContainer onClick={() => modal.open(smallModalPropsTemplate, <ForgotPasswordModal />)}>
                <Link href='/' size='big' label={gt.tp('LoginForm', 'Forgot password?')} />
              </StyledFormLinkContainer>
              <Button
                label={gt.tp('LoginForm', 'Log in')}
                height='big'
                width='full'
                color='green'
                type='submit'
                disabled={!isValid || isSubmitting}
                loading={isSubmitting}
                onClick={handleSubmit}
              />
              <OtherLogin>{gt.tp('LoginForm', 'or')}</OtherLogin>
              <GoogleLoginButton onLogin={handleSocialLogin} />
              <FacebookLoginButton onLogin={handleSocialLogin} />
              <AppleLoginButton onLogin={handleSocialLogin} />
            </StyledForm>
          )}
        </Formik>
      </StyledFormWrapper>
      <StyledImageWrapper>
        <Image src='/assets/login/loginFormPhoto.jpg' objectFit={'cover'} layout={'fill'} height={750} width={500} />
      </StyledImageWrapper>
    </StyledLoginModal>
  )
}

export const LoginModalWrapper = (Component: any): React.FC<any> => {
  const InnerComponent = props => {
    const { query, ...router } = useRouter()
    const modal = useModal()
    const showLogin = query?.showLogin

    useEffect(() => {
      const handleClose = () => {
        modal.close()
        router.replace('/', undefined, { shallow: true })
      }

      if (showLogin) {
        modal.open(
          {
            ...bigModalProps,
            handleClose,
          },
          <LoginModalView handleClose={handleClose} {...props} />
        )
      }
    }, [query?.showLogin])

    return <Component {...props} />
  }

  return InnerComponent
}

export default LoginModalView
