export const fontFamily = {
  fontSans: 'Poppins, sans-serif',
  fontSerif: 'Merriweather, serif',
  fontEarlyRC: 'SofiaPro, Poppins, sans-serif',
}

// 1rem = 16px
export const fontSize = {
  xxxs: '0.5625rem', // 9px
  xxs: '0.625rem', // 10px
  xs: '0.6875rem', // 11px
  s: '0.75rem', // 12px
  base: '0.875rem', // 14px
  m: '0.9375rem', // 15px
  l: '1.0625rem', // 17px
  xl: '1.25rem', // 20px
  xxl: '1.625rem', // 26px
  xxxl: '2rem', // 32px
  xxxxl: '3rem', // 48px
}

export const fontWeight = {
  light: 300,
  regular: 400,
  medium: 500,
  semiBold: 600,
}

export const letterSpacing = {
  tighter: '-0.04em',
  tight: '-0.02em',
  normal: '0',
  wide: '0.02em',
  wider: '0.04em',
  widest: '0.06em',
}

export const lineHeight = {
  none: 1,
  tight: 1.1,
  restful: 1.2,
  snug: 1.3,
  normal: 1.4,
  relaxed: 1.5,
  loose: 1.6,
  looser: 1.7,
  stretched: 2,
}

export default {
  fontFamily,
  fontSize,
  fontWeight,
  letterSpacing,
  lineHeight,
}
