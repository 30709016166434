// @ts-strict-ignore
import { Formik } from 'formik'
import Image from 'next/image'

import { ApolloError } from '@apollo/client'

import { Heading2, Heading3, Heading5 } from '~/theme/utils/typography'
import { formatErrorMessages } from '~/utils/formatErrorMessages'

import { AppleLoginButton } from '~/components/User/AppleLogin/AppleLoginButton'
import FacebookLoginButton from '~/components/User/FacebookLoginButton/FacebookLoginButtonView'
import GoogleLoginButton from '~/components/User/GoogleLoginButton/GoogleLoginButtonView'
import {
  CreateUserMutation,
  CreateUserMutationVariables,
  useCreateUserMutation,
  UserDataDocument,
  useUserDataQuery,
} from '~/generated/graphql'
import { gt } from '~/locale'
import Button from '~/shared/atoms/Button'
import Checkbox from '~/shared/atoms/Checkbox'
import Input from '~/shared/atoms/Input'
import { useToast } from '~/shared/atoms/Toast'
import { bigModalPropsTemplate, smallModalPropsTemplate, useModal } from '~/shared/molecules/Modal'

import LoginModal from '../LoginModal'
import { OtherLogin, StyledFormWrapper, StyledImageWrapper, StyledToastContainer } from '../LoginModal/LoginModalShards'
import VerifyAccountModal from '../VerifyAccountModal'

import {
  StyledCheckboxWrapper,
  StyledCheckIcon,
  StyledForm,
  StyledInputs,
  StyledLink,
  StyledTextWrapper,
  Wrapper,
} from './RegisterModalShards'
import { REGISTER_FORM_FIELDS, registerSchema } from './RegisterModalUtils'

interface Props {
  prefilledData?: Partial<CreateUserMutationVariables>
  hasImage?: boolean
  mainHeading?: string
  onRegister?: (data: CreateUserMutation) => void
}

const RegisterModalView: React.FC<Props> = ({ prefilledData, hasImage = true, mainHeading }) => {
  const { refetch } = useUserDataQuery()
  const [createUser] = useCreateUserMutation()
  const modal = useModal()
  const toast = useToast()

  const handleErrors = (error: ApolloError) => {
    const errors = formatErrorMessages(error)
    errors?.map(error =>
      toast.add({
        message: error,
        type: 'error',
        containerSelector: '#RegisterToastContainer',
        size: 'small',
      })
    )
  }

  const onSocialSignUp = () => {
    refetch()
  }

  return (
    <Wrapper hasImage={hasImage}>
      <StyledFormWrapper>
        <Formik
          initialValues={{
            firstName: '',
            lastName: '',
            email: '',
            phoneNumber: '',
            password: '',
            marketingConsentAccepted: false,
            ...prefilledData,
          }}
          validationSchema={registerSchema}
          onSubmit={async (values, { setSubmitting }) => {
            await createUser({
              variables: {
                firstName: values.firstName,
                lastName: values.lastName,
                email: values.email,
                password: values.password,
                phoneNumber: values.phoneNumber,
                marketingConsentAccepted: values.marketingConsentAccepted,
              },
              onCompleted: () => {
                modal.open(smallModalPropsTemplate, <VerifyAccountModal handleClose={modal.close} />)
              },
              refetchQueries: [{ query: UserDataDocument }, 'UserData'],
              onError: error => handleErrors(error),
            })
            setSubmitting(false)
          }}>
          {({ errors, touched, values, handleSubmit, isSubmitting, isValid, handleChange, handleBlur }) => (
            <StyledForm>
              <Heading2 fontWeight='medium'>{gt.tp('RegisterForm', mainHeading)}</Heading2>
              <Heading5 fontWeight='medium'>
                {gt.tp('RegisterForm', 'Already have an account?')}{' '}
                <StyledLink onClick={() => modal.open(bigModalPropsTemplate, <LoginModal handleClose={modal.close} />)}>
                  {gt.tp('RegisterForm', 'Log in here')}
                </StyledLink>
              </Heading5>
              <StyledToastContainer id={'RegisterToastContainer'} />
              <StyledInputs>
                {REGISTER_FORM_FIELDS.map(field => (
                  <Input
                    key={field.name}
                    title={gt.tp('RegisterForm', field.title)}
                    name={field.name}
                    type={field.type}
                    description={gt.tp('RegisterForm', field.description)}
                    actionsRight={[]}
                    error={touched[field.name] && !!errors[field.name]}
                    errorMessage={touched[field.name] && gt.tp('Error', errors[field.name])}
                    height={'big'}
                    width={'full'}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values[field.name]}
                  />
                ))}
              </StyledInputs>
              <StyledCheckboxWrapper>
                <Checkbox
                  name='marketingConsentAccepted'
                  onChange={handleChange}
                  onBlur={handleBlur}
                  checked={values.marketingConsentAccepted}
                  label={gt.tp(
                    'RegisterForm',
                    'I want to: collect and benefit from DISCOUNT CODES, be notified of Festivals, obtain eBook “RESTAURANTS’ RECIPES” (price PLN 78), therefore I consent to the sending of the commercial information'
                  )}
                  showMore={gt.tp(
                    'RegisterForm',
                    'to me by Restaurant Club sp. z o. o. to the contact details I provided and the use of these data for direct marketing purposes via electronic terminal devices (e-mail, online social networking sites, phone number provided by me - SMS, telephone contact).'
                  )}
                />
              </StyledCheckboxWrapper>
              <Button
                label={gt.tp('RegisterForm', 'Register')}
                height='big'
                width='full'
                color='green'
                disabled={!isValid || isSubmitting}
                loading={isSubmitting}
                onClick={handleSubmit}
              />
              <OtherLogin>{gt.tp('LoginForm', 'or')}</OtherLogin>
              <GoogleLoginButton onLogin={onSocialSignUp} label={gt.tp('Register', 'Sign up with Google')} />
              <FacebookLoginButton onLogin={onSocialSignUp} label={gt.tp('Register', 'Sign up with Facebook')} />
              <AppleLoginButton onLogin={onSocialSignUp} label={gt.tp('Register', 'Sign up with Apple')} />
            </StyledForm>
          )}
        </Formik>
      </StyledFormWrapper>
      {hasImage && (
        <StyledImageWrapper>
          <Image
            layout='fill'
            objectFit='cover'
            height={750}
            objectPosition='right'
            width={500}
            src='/assets/login/registerFormPhoto.jpg'
          />
          <StyledTextWrapper>
            <Heading3 fontWeight='medium'>{gt.tp('RegisterForm', 'Register your Guest Account today!')}</Heading3>
            <Heading5 fontWeight='medium'>
              <StyledCheckIcon icon='check-bold' />
              {gt.tp('RegisterForm', 'Invite your loved ones!')}
            </Heading5>
            <Heading5 fontWeight='medium'>
              <StyledCheckIcon icon='check-bold' />
              {gt.tp('RegisterForm', 'Book your favorite menu. Discover new restaurants.')}
            </Heading5>
            <Heading5 fontWeight='medium'>
              <StyledCheckIcon icon='check-bold' />
              {gt.tp('RegisterForm', 'Thousands of signature dishes, hundreds of best restaurants awaits for you!')}
            </Heading5>
            <Heading5 fontWeight='medium'>
              <StyledCheckIcon icon='check-bold' />
              {gt.tp('RegisterForm', 'Benefit from discounts and offers thanks to RestaurantClub friends.')}
            </Heading5>
          </StyledTextWrapper>
        </StyledImageWrapper>
      )}
    </Wrapper>
  )
}

RegisterModalView.defaultProps = {
  prefilledData: undefined,
  hasImage: true,
  mainHeading: gt.tp('RegisterForm', 'Register'),
}

export default RegisterModalView
