import styled from '@emotion/styled'

import { mediaQueryUp } from '~/theme/utils/grid'
import { Heading6 } from '~/theme/utils/typography'

import Button from '~/shared/atoms/Button'
import Icon from '~/shared/atoms/Icon'
import { NameInput, Row, SubmitButton } from '~/shared/molecules/Search/SearchShards'

export const Wrapper = styled.div<{ type: string }>`
  white-space: nowrap;
  text-overflow: ellipsis;
  width: 100%;
  height: fit-content;

  ${mediaQueryUp('m')} {
    border-radius: 0.5rem 0.5rem 0 0;
    box-shadow: ${({ theme }) => theme.commons.shadow.l};
    max-width: 25.75rem;
  }

  ${mediaQueryUp('l')} {
    border: 1px solid ${({ theme }) => theme.colors.gray[200]};
    border-radius: 0.5rem;
    flex-shrink: 0;
  }

  ${Row} {
    width: calc(100vw - 2rem);
    max-width: calc(25.75rem - 4rem);
  }

  ${NameInput},
  ${SubmitButton} {
    display: none;
  }
`

export const SearchWrapper = styled.div`
  padding: 0.2rem;
  width: 100vw;
  border-bottom: 2px solid ${({ theme }) => theme.colors.gray[100]};

  ${mediaQueryUp('m')} {
    padding: 2rem 0.2rem;
    width: 100%;
  }
`

export const Results = styled.div`
  padding: 1rem;
  width: 100%;
  padding-bottom: 1.5rem;
  position: relative;

  ${mediaQueryUp('m')} {
    padding: 2rem;
    padding-top: 1.5rem;
  }
`

export const Slotlist = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  padding-top: 1rem;
`

export const ButtonIcon = styled(Icon)`
  width: 1.5rem;
  height: 1.5rem;
  filter: ${({ theme }) => theme.filters.gray[0]};
`

export const Actions = styled.div`
  display: flex;
  gap: 1.5rem;
  padding-top: 1.5rem;

  & img {
    filter: ${({ theme }) => theme.filters.gray[300]};
    height: 0.675rem;
  }
  & button:last-of-type img {
    transform: rotate(180deg);
  }
`

export const SlotButton = styled(Button)<{ disabled: boolean }>`
  height: 2.875rem;
  ${Heading6} {
    display: flex;
    gap: 1rem;
    align-items: center;
  }

  ${ButtonIcon} {
    margin-left: -0.25rem;
    transform: rotate(180deg);
  }

  ${({ theme, disabled }) =>
    disabled &&
    `
  color: ${theme.colors.gray[800]};
  filter: grayscale(100%);
  ${ButtonIcon} {
    display: none;
  };`}
`

export const SkeletonSlotButton = styled(SlotButton)`
  background-color: ${({ theme }) => theme.colors.gray[300]};
  border: 1px solid ${({ theme }) => theme.colors.gray[300]};
`

export const PriceAfterComma = styled.span`
  position: relative;
  font-size: 0.6rem;
  top: -0.4em;
`
export const SkeletonSlotsElement = styled.div`
  border-radius: 0.625rem;
  background-color: ${({ theme }) => theme.colors.gray[200]};
  height: 100px;
  margin: 12px;

  animation: pulse 4s infinite;

  @keyframes pulse {
    0%,
    100% {
      background-color: ${({ theme }) => theme.colors.gray[200]};
    }
    50% {
      background-color: ${({ theme }) => theme.colors.gray[100]};
    }
  }
`
export const SkeletonSlotsHeaderElement = styled.div`
  border-radius: 0.625rem;
  background-color: ${({ theme }) => theme.colors.gray[200]};
  height: 40px;
  margin: 12px;

  animation: pulse 4s infinite;

  @keyframes pulse {
    0%,
    100% {
      background-color: ${({ theme }) => theme.colors.gray[200]};
    }
    50% {
      background-color: ${({ theme }) => theme.colors.gray[100]};
    }
  }
`

export const SkeletonDividerElement = styled.div`
  border-bottom: 1px solid ${({ theme }) => theme.colors.gray[200]};
`

export const PresaleContainer = styled.div`
  margin: 1rem 0;
`

export const PresaleInputContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 1rem;
`
