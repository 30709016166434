import { UserDataQuery, useUserDataQuery } from '~/generated/graphql'

interface UseUserOutput {
  user?: UserDataQuery['user']
  loading: boolean
}

const useUser = (): UseUserOutput => {
  const { data, loading } = useUserDataQuery({
    nextFetchPolicy: 'cache-first',
  })

  return {
    user: data?.user,
    loading,
  }
}

export default useUser
