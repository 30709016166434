// @ts-strict-ignore
import { rgba } from 'polished'

const _black = '#000' // eslint-disable-line
const _white = '#fff'

const gray = {
  0: '#FFFFFF',
  20: '#FDFDFD',
  50: '#FAFAFA',
  100: '#F5F5F5',
  200: '#EAEAEA',
  300: '#E1E1E1',
  400: '#CACACA',
  500: '#B3B3B3',
  600: '#8E8E8E',
  700: '#6E6E6E',
  800: '#4B4B4B',
  900: '#2F2F2F',
  1000: '#242424',
}

const orange = {
  brand: '#F8B800',
  50: '#FBF6E7',
  100: '#FFFBEE',
  200: '#FFF6DB',
  300: '#FFE08A',
  400: '#F6B700',
  450: '#F19F01',
  500: '#F1A001',
  600: '#DC7F12',
}

const teal = {
  100: '#F3F6EF',
  300: '#D7E8C1',
  400: '#6CB214',
  500: '#3F810B',
  600: '#0B4C00',
}

const red = {
  100: '#F9EDED',
  200: '#FFE2E2',
  300: '#F0BFBF',
  400: '#D64040',
  500: '#B82525',
  600: '#A11818',
}

const green = {
  100: '#F3F6EF',
  200: '#D7E8C1',
  300: '#80C069',
  400: '#6CB214',
  500: '#619C01',
  600: '#4A8020',
}

const yellow = {
  100: '#E5E5E5',
}

const blue = {
  100: '#EAF1FC',
  400: '#1434CB',
  500: '#132CA0',
  600: '#12247B',
}

const _selectiveYellow = '#F6B700'
const _kidnapper = '#dbe7cf'
const _willow = '#aac778'
const _cucumber = '#8ab062'
const _sanmarino = '#4f6aa3'
const _facebookBlue = '#3c5a99'
const _facebookBlueDark = '#314c85'

const color = {
  primary: _selectiveYellow,
  text: gray[1000],
  textInverted: _white,
  layoutBg: _white,
  overlay: rgba(_black, 0.8),
  error: red[400],

  bgColor: gray[0],
  bgColorAlt: gray[50],

  checkbox: {
    bgEmpty: gray[0],
    bgSelected: orange[400],
    borderEmpty: gray[400],
    borderSelected: orange[400],
    text: gray[900],
    textSecondary: gray[600],
    bgSelectedHover: orange[500],
    borderEmptyHover: orange[500],
    borderSelectedHover: orange[500],
    bgEmptyDisabled: gray[200],
    bgSelectedDisabled: gray[300],
    borderEmptyDisabled: gray[300],
    borderSelectedDisabled: gray[300],
    textDisabled: gray[400],
  },

  radio: {
    bg: gray[0],
    border: gray[400],
    borderSelected: orange[400],
    borderHover: orange[400],
    borderSelectedHover: orange[500],
    borderDisabled: gray[200],
    text: gray[900],
    textDisabled: gray[400],
  },

  dropdownSelect: {
    bg: _white,
    bgSelected: teal[100],
    bgDisabled: gray[100],
    border: gray[400],
    borderHover: gray[600],
    placeholder: gray[600],
    text: gray[900],
    optionIcon: teal[400],
  },

  switcher: {
    bg: gray[500],
    bgSelected: teal[400],
    bgDisabled: gray[300],
    toggle: _white,
    toggleDisabled: gray[50],
    text: gray[900],
    textDisabled: gray[400],
  },

  // old styles
  buttonBlank: {
    bg: 'transparent',
    bgHover: gray[20],
    bgActive: gray[20],
    bgInactive: gray[70],
    text: gray[900],
    textHover: gray[900],
    textActive: gray[900],
    textInactive: gray[50],
    border: gray[80],
    borderHover: gray[80],
    borderActive: gray[80],
    borderInactive: gray[80],
  },
  buttonAccept: {
    bg: _cucumber,
    bgHover: _willow,
    bgActive: _willow,
    bgInactive: _kidnapper,
    text: _white,
    textHover: _white,
    textActive: _white,
    textInactive: _white,
  },
  buttonFacebook: {
    bg: _facebookBlue,
    bgHover: _sanmarino,
    bgActive: _facebookBlueDark,
    bgInactive: rgba(_facebookBlue, 0.3),
    text: _white,
    textHover: _white,
    textActive: _white,
    textInactive: _white,
  },
}

export const colors = {
  ...color,
  gray,
  orange,
  teal,
  red,
  green,
  yellow,
  blue,
}
