// @ts-strict-ignore
import { css, Theme } from '@emotion/react'
import styled from '@emotion/styled'

import { mediaQueryUp } from '~/theme/utils/grid'

import { fontWeight } from '~/theme/text'

type FontWeight = keyof typeof fontWeight
type FontColor = 'primary' | 'orange'

export const Heading1 = styled.h1<{ fontWeight?: FontWeight }>`
  font-size: ${({ theme }) => theme.text.fontSize.xxxxl};
  font-weight: ${({ theme, fontWeight = 'regular' }) => theme.text.fontWeight[fontWeight]};
  line-height: ${({ theme }) => theme.text.lineHeight.restful};
  letter-spacing: ${({ theme }) => theme.text.letterSpacing.tighter};
`

export const Heading2 = styled.h2<{ fontWeight?: FontWeight }>`
  font-size: ${({ theme }) => theme.text.fontSize.xxxl};
  font-weight: ${({ theme, fontWeight = 'regular' }) => theme.text.fontWeight[fontWeight]};
  line-height: ${({ theme }) => theme.text.lineHeight.tight};
  letter-spacing: ${({ theme }) => theme.text.letterSpacing.tighter};
`

export const Heading3 = styled.h3<{ fontWeight?: FontWeight }>`
  font-size: ${({ theme }) => theme.text.fontSize.xxl};
  font-weight: ${({ theme, fontWeight = 'regular' }) => theme.text.fontWeight[fontWeight]};
  line-height: ${({ theme }) => theme.text.lineHeight.snug};
  letter-spacing: ${({ theme }) => theme.text.letterSpacing.tight};
`

export const Heading4 = styled.h4<{ fontWeight?: FontWeight }>`
  font-size: ${({ theme }) => theme.text.fontSize.xl};
  font-weight: ${({ theme, fontWeight = 'regular' }) => theme.text.fontWeight[fontWeight]};
  line-height: ${({ theme }) => theme.text.lineHeight.normal};
  letter-spacing: ${({ theme }) => theme.text.letterSpacing.tight};
`

export const Heading5 = styled.h5<{ fontWeight?: FontWeight }>`
  font-size: ${({ theme }) => theme.text.fontSize.l};
  font-weight: ${({ theme, fontWeight = 'regular' }) => theme.text.fontWeight[fontWeight]};
  line-height: ${({ theme }) => theme.text.lineHeight.relaxed};
  letter-spacing: ${({ theme }) => theme.text.letterSpacing.normal};
`

export const Heading6 = styled.h6<{ fontWeight?: FontWeight }>`
  font-size: ${({ theme }) => theme.text.fontSize.m};
  font-weight: ${({ theme, fontWeight = 'regular' }) => theme.text.fontWeight[fontWeight]};
  line-height: ${({ theme }) => theme.text.lineHeight.loose};
  letter-spacing: ${({ theme }) => theme.text.letterSpacing.normal};
`

export const HeadingEyebrow = styled.div`
  font-size: ${({ theme }) => theme.text.fontSize.s};
  font-weight: ${({ theme }) => theme.text.fontWeight.semiBold};
  line-height: ${({ theme }) => theme.text.lineHeight.normal};
  letter-spacing: ${({ theme }) => theme.text.letterSpacing.wider};
`

export const Body1 = styled.div<{ fontWeight?: FontWeight }>`
  font-size: ${({ theme }) => theme.text.fontSize.base};
  font-weight: ${({ theme, fontWeight = 'regular' }) => theme.text.fontWeight[fontWeight]};
  line-height: ${({ theme }) => theme.text.lineHeight.restful};
  letter-spacing: ${({ theme }) => theme.text.letterSpacing.tight};
`

export const Body2 = styled.div<{ fontWeight?: FontWeight }>`
  font-size: ${({ theme }) => theme.text.fontSize.s};
  font-weight: ${({ theme, fontWeight }) => theme.text.fontWeight[fontWeight || 'normal']};
  line-height: ${({ theme }) => theme.text.lineHeight.restful};
  letter-spacing: ${({ theme }) => theme.text.letterSpacing.wide};
`

export const Body2Link = styled(Body2)<{ color?: FontColor }>`
  width: fit-content;
  display: block;
  color: ${({ color, theme }) => getColors(color, theme)[0]};
  cursor: pointer;
  position: relative;
  transition: color 0.2s;

  &::after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 1px;
    background-color: transparent;
    transition: background-color 0.2s;
  }

  &:hover {
    color: ${({ color, theme }) => getColors(color, theme)[0]};

    &::after {
      background-color: ${({ color, theme }) => getColors(color, theme)[1]};
    }
  }
`

export const getColors = (color: FontColor, theme: Theme) => {
  if (color === 'orange') return [theme.colors.orange[500], theme.colors.orange[400]]
  return [theme.colors.gray[700], theme.colors.gray[400]]
}

export const TileTitle = styled.div`
  margin-bottom: 3px;
  font-weight: 600;
  font-size: 20px;
  line-height: 120%;
  letter-spacing: -0.02em;
  color: ${({ theme }) => theme.colors.gray[1000]};

  ${mediaQueryUp('m')} {
    margin-bottom: 6px;
  }
`

export const TileDescription = styled.div<{
  marginTop?: string
  emphasized?: boolean
}>`
  display: inline-flex;
  margin-top: ${({ marginTop }) => marginTop || 0};
  font-weight: 400;
  font-size: 14px;
  line-height: 150%;
  letter-spacing: -0.01em;
  color: ${({ theme, emphasized }) => (emphasized ? theme.colors.gray[1000] : theme.colors.gray[700])};
`

export const BlogHeading1 = styled.h1<{ fontWeight?: FontWeight }>`
  font-family: ${({ theme }) => theme.text.fontFamily.fontSerif};
  font-size: 2.25rem;
  font-weight: ${({ theme, fontWeight = 'regular' }) => theme.text.fontWeight[fontWeight]};
  line-height: ${({ theme }) => theme.text.lineHeight.relaxed};
  text-align: center;
`

export const BlogHeading2 = styled.h2<{ fontWeight?: FontWeight }>`
  font-family: ${({ theme }) => theme.text.fontFamily.fontSerif};
  font-size: 1.75rem;
  font-weight: ${({ theme, fontWeight = 'regular' }) => theme.text.fontWeight[fontWeight]};
  line-height: ${({ theme }) => theme.text.lineHeight.relaxed};
`

export const BlogHeading3 = styled.h3<{ fontWeight?: FontWeight }>`
  font-family: ${({ theme }) => theme.text.fontFamily.fontSerif};
  font-size: 1.5rem;
  font-weight: ${({ theme, fontWeight = 'regular' }) => theme.text.fontWeight[fontWeight]};
  line-height: ${({ theme }) => theme.text.lineHeight.looser};
`

export const BlogHeading4 = styled.h4<{ fontWeight?: FontWeight }>`
  font-family: ${({ theme }) => theme.text.fontFamily.fontSerif};
  font-size: ${({ theme }) => theme.text.fontSize.xl};
  font-weight: ${({ theme, fontWeight = 'regular' }) => theme.text.fontWeight[fontWeight]};
  line-height: ${({ theme }) => theme.text.lineHeight.relaxed};
`

export const BlogBodySmall = styled.p<{ fontWeight?: FontWeight }>`
  font-size: ${({ theme }) => theme.text.fontSize.base};
  line-height: ${({ theme }) => theme.text.lineHeight.relaxed};
  letter-spacing: '0.01em';
`

export const lineClamp = (noOfLines: number) => css`
  display: -webkit-box;
  -webkit-line-clamp: ${noOfLines};
  -webkit-box-orient: vertical;
  overflow: hidden;
`
