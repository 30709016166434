import { gt } from '~/locale'

export const defaultMetadatas = {
  title: gt.tp('Head', 'No. 1 ONLINE RESERVATIONS | RestaurantClub'),
  description: gt.tp(
    'Head',
    `
    Restaurant Week is a unique opportunity
    to get to know the best restaurants. Don't put off experiences
    and book today!`
  ),
  imageUrl: 'https://restaurantclub.pl/assets/rc_share.png',
  imageAlt: 'rc_share',
  siteName: gt.tp('Head', 'RestaurantClub.pl'),
  url: 'https://restaurantclub.pl',
}
