import dayjs from 'dayjs'

import { getDailyDateRange, getPossibleDailySlots } from '~/utils/graphqlDataFormatters'

import SlotSelectionPanel from '~/components/Reservation/SlotSelectionPanel'
import { RESERVABLE_TYPES } from '~/constants'
import { gt } from '~/locale'

import { HeaderContainer, RestaurantName, SlotsText, StyledSearchSlotsModal } from './SearchSlotsModalShards'
import { SearchSlotsModalProps } from './SearchSlotsModalTypes'

const SearchSlotsModalView = ({ restaurant, reservableInput, reservable }: SearchSlotsModalProps) => {
  return (
    <StyledSearchSlotsModal>
      <SlotSelectionPanel
        header={
          <HeaderContainer>
            <RestaurantName>{restaurant?.name}</RestaurantName>
            <SlotsText>{gt.tp('Restaurant', 'Nearest available tables')}</SlotsText>
          </HeaderContainer>
        }
        reservableInput={reservableInput}
        fieldsToHide={['name', 'submit']}
        type={RESERVABLE_TYPES.DAILY}
        dateRange={getDailyDateRange(dayjs.parseZone())}
        possibleSlots={getPossibleDailySlots(reservable)}
      />
    </StyledSearchSlotsModal>
  )
}

export default SearchSlotsModalView
