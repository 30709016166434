import { useMediaQuery } from 'react-responsive'

import dayjs from 'dayjs'
import Image from 'next/image'
import { useRouter } from 'next/router'

import { mediaQueryIsMobile } from '~/theme/utils/grid'
import { Heading3, Heading6 } from '~/theme/utils/typography'
import { setCookiesAsJson } from '~/utils/cookie'

import config from '~/config/index'
import { COOKIE } from '~/constants'
import { gt } from '~/locale'
import Button from '~/shared/atoms/Button'

import { Actions, Logo, UpperHeading, Wrapper } from './ConfirmLegalAgeModalShards'

interface ConfirmLegalAgeModalProps {
  handleClose: () => void
}

const ConfirmLegalAgeModal = ({ handleClose }: ConfirmLegalAgeModalProps) => {
  const isMobile = useMediaQuery({ query: mediaQueryIsMobile })
  const { tp } = gt
  const router = useRouter()

  const handleClick = (confirmed: boolean) => {
    if (confirmed) {
      setCookiesAsJson(COOKIE.ADULT, true, null, { expires: dayjs().add(10, 'years').toDate() })
      handleClose()
    } else {
      router.push(config().fb)
    }
  }

  return (
    <Wrapper>
      {isMobile && (
        <Logo>
          <Image
            layout={'fill'}
            src={'/assets/logo/logo-club.svg'}
            objectFit={'contain'}
            objectPosition={'center'}
            alt={'RestaurantClub Logo'}
          />
        </Logo>
      )}
      <UpperHeading>{tp('ConfirmLegalAgeModal', 'The music is on, the table is set...')}</UpperHeading>
      <Heading3 fontWeight='semiBold'>{tp('ConfirmLegalAgeModal', 'Please confirm your age and join us at the table!')}</Heading3>
      <Heading6 fontWeight='regular'>
        {tp('ConfirmLegalAgeModal', 'The sale of alcoholic beverages is restricted to persons over the age of 18.')}
      </Heading6>
      <Actions>
        <Button
          label={tp('ConfirmLegalAgeModal', "I'm 18+ years old")}
          color='orange'
          width='full'
          height='big'
          fontWeight='regular'
          onClick={() => handleClick(true)}
        />
        <Button
          label={tp('ConfirmLegalAgeModal', 'Cancel')}
          color='secondary'
          width='full'
          height='big'
          fontWeight='regular'
          onClick={() => handleClick(false)}
        />
      </Actions>
    </Wrapper>
  )
}

export default ConfirmLegalAgeModal
