// @ts-strict-ignore

import dayjs from 'dayjs'

import { dayjsToString } from '~/utils/graphqlDataFormatters'

import { FerListQueryVariables, FestivalCodesEnum, ReservableEnum } from '~/generated/graphql'

import { AllQueryVariables, FilterSection } from '../../ListingPageContent/ListingPageContentTypes'
import { getTagAndRestaurantIds } from '../RestaurantListingPage/RestaurantListingPageUtils'

export const getRangeStart = (startsOn?: dayjs.Dayjs, reservableFrom?: dayjs.Dayjs): dayjs.Dayjs => {
  if (!startsOn && !reservableFrom) return dayjs.parseZone().add(1, 'day')
  if (!startsOn || !reservableFrom) return startsOn || reservableFrom
  return startsOn.clone().isAfter(reservableFrom, 'hour') ? startsOn : reservableFrom
}

export const getFERListQueryVariables = ({
  selectedRegion,
  filters,
  search,
  festivalEditionId,
  endCursor,
  tagsAndNames,
  reservableFrom,
  endsOn,
  first,
}: AllQueryVariables): FerListQueryVariables => {
  const { tagIds, restaurantIds } = getTagAndRestaurantIds(tagsAndNames)
  const allTagIds = [...filters.tags.map(({ id }) => id), ...tagIds]
  const { date, slot, peopleCount } = search || {}

  const rangeStart = date || getRangeStart(dayjs.parseZone(reservableFrom))
  const rangeEnd = date || endsOn

  return {
    region_id: selectedRegion.id,
    festival_edition_ids: festivalEditionId,
    reservation_filters: {
      startsOn: dayjsToString(rangeStart),
      endsOn: dayjsToString(rangeEnd),
      hours: slot ? [slot] : [],
      peopleCount: peopleCount,
      reservableType: ReservableEnum.FestivalEditionRestaurant,
    },
    ...(allTagIds.length && { tag_ids: allTagIds }),
    ...(restaurantIds.length && { ids: restaurantIds }),
    first: first,
    after: endCursor,
  }
}

export const getFilterSections = ({
  festivalCode,
  isExternalSale,
}: {
  festivalCode: FestivalCodesEnum
  isExternalSale: boolean
}): FilterSection[] => {
  if (isExternalSale) {
    return []
  }

  switch (festivalCode) {
    case FestivalCodesEnum.Gw:
      return ['popular']
    default:
      return ['popular', 'cuisines', 'dishes']
  }
}
